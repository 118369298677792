<template>
  <div class="aboutUs">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {},
  computed: {},
  destroyed() {},
};
</script>
<style lang="scss" scoped>
</style>
